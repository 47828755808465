<template>
  <div class="lineCollect">
    <div class="form-area">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="right"
        label-width="98px"
      >
        <el-form-item label="提交时间：" class="code-time-range">
          <el-date-picker
            v-model="date"
            type="daterange"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="出发地城市：">
          <el-cascader
            ref="cascader"
            :options="cityList"
            :props="props"
            clearable
            @change="handleCascaderCity(1)"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="目的地城市：">
          <el-cascader
            ref="cascader2"
            :options="cityList"
            :props="props"
            clearable
            @change="handleCascaderCity(2)"
          ></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryFun"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table ref="table" :table-data="tableData" :title-name="titleName"> </Table>
    <div class="pagination">
      <el-pagination
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { queryRouteCollectionPage, getCityCodeAPI } from "@/api/lib/api";
export default {
  data() {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        createTimeStart: "",
        createTimeEnd: "",
        departureCity: "",
        destinationCity: "",
      },
      total: 0,
      props: {
        value: "adcode",
        label: "name",
        children: "districts",
        checkStrictly: true,
      },
      cityList: [],
      date: null,
      tableData: [],
      titleName: [
        {
          title: "提交时间",
          props: "createTime",
          width: 200,
        },
        {
          title: "出发地城市",
          props: "departureCity",
        },
        {
          title: "出发地",
          props: "departure",
        },
        {
          title: "目的地城市",
          props: "destinationCity",
        },
        {
          title: "目的地",
          props: "destination",
        },
        {
          title: "期望到达时间",
          props: "expectedArrivalTime",
        },
        {
          title: "出行目的",
          props: "travelPurpose",
        },
        {
          title: "提交人",
          props: "submitterPhone",
        },
      ],
    };
  },
  mounted() {
    this.queryFun();
    this.getCityCode();
  },
  methods: {
    queryFun() {
      this.form.currentPage = 1;
      this.renderData();
    },
    renderData() {
      if (this.date) {
        this.form.createTimeStart = this.date[0] + " 00:00:00";
        this.form.createTimeEnd = this.date[1] + " 23:59:59";
      } else {
        this.form.createTimeStart = null;
        this.form.createTimeEnd = null;
      }
      queryRouteCollectionPage(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    //获取城市代码
    getCityCode() {
      getCityCodeAPI().then((res) => {
        if (res.code === "SUCCESS") {
          this.cityList = this.getTreeData(res.data[0].districts);
        }
      });
    },
    getTreeData(data) {
      for (let index = 0; index < data.length; index++) {
        if (data[index].districts.length < 1) {
          data[index].districts = undefined;
        } else {
          this.getTreeData(data[index].districts);
        }
      }
      return data;
    },
    //选择城市
    handleCascaderCity(val) {
      if (val === 1) {
        const node = this.$refs.cascader.getCheckedNodes();
        if (!node.length) this.form.departureCity = "";
        else this.form.departureCity = node[0].label;
      } else if (val === 2) {
        const node2 = this.$refs.cascader2.getCheckedNodes();
        if (!node2.length) this.form.destinationCity = "";
        else this.form.destinationCity = node2[0].label;
      }
    },
    // 分页页数发生变化
    onCurrentChange(val) {
      this.form.currentPage = val;
      this.renderData();
    },
    // 分页change方法
    onSizeChange(val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.renderData();
    },
  },
};
</script>

<style lang="scss" scoped>
.lineCollect {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  background: #ffffff;
  .code-time-range {
    .el-date-editor {
      width: 13vw !important;
      ::v-deep .el-range-separator {
        line-height: 24px;
      }
    }
  }
  .Table {
    margin-top: 16px;
  }
}
</style>
